import SuccessMessageModal from "@components/Reusable/SuccessMessageModal";
import WarningMessageModal from "@components/Reusable/WarningMessageModal";
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
} from "@mui/material";
import { createPlace } from "@services/placeService";
import React, { useState } from "react";

const CreatePlaceModal = ({ isOpen, onClose }) => {  
  const ORGANIZATION = JSON.parse(sessionStorage.getItem("organization"));
  const [newPlace, setNewPlace] = useState({
    name_place: "",
    address: "",
    nit: "",
    user_id: sessionStorage.getItem("userId"),
    organizacion_id: ORGANIZATION.organizacion_id,

  });

  const [isLoading, setIsLoading] = useState(false);
  const [successMessageVisible, setSuccessMessageVisible] = useState(false);
  const [warningMessageVisible, setWarningMessageVisible] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const handleChange = (e) => {
    const { name, value } = e.target;
    setNewPlace((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSave = async (e) => {
    e.preventDefault();

    // Validación básica
    if (!newPlace.name_place || !newPlace.address || !newPlace.nit) {
      setErrorMessage("Todos los campos con (*) son obligatorios.");
      setWarningMessageVisible(true);
      return;
    }

    setIsLoading(true);
    setErrorMessage("");

    try {
      const response = await createPlace(newPlace);
      setIsLoading(false);
      setSuccessMessageVisible(true);
      setNewPlace({
        name_place: "",
        address: "",
        nit: "",
        user_id: sessionStorage.getItem("userId").toString(),
        organizacion_id: ORGANIZATION.organizacion_id,
      });
    } catch (error) {
      setErrorMessage("Error al registrar la sede. Intente de nuevo.");
      setWarningMessageVisible(true);
      setIsLoading(false);
      console.error("Error al crear la sede:", error);
    }
  };

  return (
    <>
      <Dialog open={isOpen} onClose={onClose} fullWidth maxWidth="sm">
        <DialogTitle>Registrar Nueva Sede</DialogTitle>
        <DialogContent dividers>
          {isLoading ? (
            <Box display="flex" justifyContent="center" p={2}>
              <CircularProgress />
            </Box>
          ) : (
            <Box component="form">
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    label="Nombre del Lugar *"
                    name="name_place"
                    value={newPlace.name_place}
                    onChange={handleChange}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    label="Dirección *"
                    name="address"
                    value={newPlace.address}
                    onChange={handleChange}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    label="NIT *"
                    name="nit"
                    type="number"
                    value={newPlace.nit}
                    onChange={handleChange}
                    fullWidth
                  />
                </Grid>
              </Grid>
            </Box>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} style={{ color: "#1d1d1b" }}>
            Cancelar
          </Button>
          <Button
            onClick={handleSave}
            style={{ backgroundColor: "#0A2139", color: "#f1f1f1" }}
            disabled={isLoading}
            variant="contained"
          >
            Guardar
          </Button>
        </DialogActions>

        {/* Modales Reutilizables */}
        <SuccessMessageModal
          isOpen={successMessageVisible}
          message="Sede registrada exitosamente."
          onClose={() => {
            onClose();
            setSuccessMessageVisible(false);
          }}
        />
        <WarningMessageModal
          isOpen={warningMessageVisible}
          message={errorMessage}
          onClose={() => setWarningMessageVisible(false)}
        />
      </Dialog>
    </>
  );
};

export default CreatePlaceModal;
