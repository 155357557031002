import api from "@utils/api";

const userName = sessionStorage.getItem("username");
const ORGANIZATION = JSON.parse(sessionStorage.getItem("organization"));
export const getUsers = () =>
  api.get(
    `/users/organizacion/${ORGANIZATION.organizacion_id}?page=1&per_page=50`);
export const getUserById = (id) => api.get(`/users/${id}`);
export const getPlacesByUserName = () => api.get(`/users/username/${userName}`);
export const createUser = (user) => api.post("/users/register", user);
export const createAdminUser = (user) => api.post("/admin_user/", user);
export const updateUser = (id, user) => api.put(`/users/${id}`, user);
export const getUserByPlaceId = (id) => api.get(`/users/place/${id}/non-admin-users/`);

export const getAdminUsers = () =>
  api.get(
    `/admin_user/organizacion/${ORGANIZATION.organizacion_id}?page=1&per_page=50`
  );
export const getAdminUsersByPlaceId = (id) => api.get(`/users/place/${id}/admin-users/`);
export const updateAdminUser = (id, user) => api.put(`/admin_user/${id}`, user);
export const deleteUser = (id) => api.delete(`/users/${id}`);
export const deleteAdminUser = (id) => api.delete(`/admin_user/${id}`);
export const getPlacesByUserNameParam = (username) => api.get(`/users/username/${username}`);