import SuccessMessageModal from "@components/Reusable/SuccessMessageModal";
import WarningMessageModal from "@components/Reusable/WarningMessageModal";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  Typography,
} from "@mui/material";
import {
  assignPlaceToUser,
  removePlaceFromUser,
} from "@services/userPlaceService";
import {
  getPlacesByUserName,
  getPlacesByUserNameParam,
  getUsers,
  getUserByPlaceId,
} from "@services/userService";
import React, { useEffect, useState } from "react";
import Select from "react-select";

const ManageUserModal = ({ isOpen, onClose }) => {
  const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [assignedPlaces, setAssignedPlaces] = useState([]);
  const [availablePlaces, setAvailablePlaces] = useState([]);
  const [selectedPlace, setSelectedPlace] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isSuccessOpen, setIsSuccessOpen] = useState(false);
  const [isWarningOpen, setIsWarningOpen] = useState(false);
  const [modalMessage, setModalMessage] = useState("");
  const [selectedPlaceId, setSelectedPlaceId] = useState(
    parseInt(sessionStorage.getItem("selectedPlaceId"), 10) || null
  );

  useEffect(() => {
    if (isOpen) {
      loadUsers();
      loadAvailablePlaces();
    }
  }, [isOpen]);

  useEffect(() => {
    if (selectedUser) {
      loadAssignedPlaces();
    }
  }, [selectedUser]);

  const loadUsers = async () => {
    if (selectedPlaceId) {
      try {
        const response = await getUserByPlaceId(selectedPlaceId);
        const userOptions = response.data.map((user) => ({
          value: user.id,
          label: user.username,
        }));
        setUsers(userOptions);
      } catch (error) {
        setModalMessage("Error al cargar los usuarios.");
        setIsWarningOpen(true);
      }
    } else {
      try {
        const response = await getUsers();
        const userOptions = response.data.data.map((user) => ({
          value: user.id,
          label: user.username,
        }));
        setUsers(userOptions);
      } catch (error) {
        setModalMessage("Error al cargar los usuarios.");
        setIsWarningOpen(true);
      }
    }
  };

  const loadAssignedPlaces = async () => {
    setIsLoading(true);
    try {
      const response = await getPlacesByUserNameParam(selectedUser.label);
      setAssignedPlaces(response.data[0].places);
    } catch (error) {
      setModalMessage("Error al cargar las sedes asignadas.");
      setIsWarningOpen(true);
    } finally {
      setIsLoading(false);
    }
  };

  const loadAvailablePlaces = async () => {
    try {
      const response = await getPlacesByUserName();
      const placeOptions = response.data[0].places.map((place) => ({
        value: place.id,
        label: place.name_place,
      }));
      setAvailablePlaces(placeOptions);
    } catch (error) {
      setModalMessage("Error al cargar las sedes disponibles.");
      setIsWarningOpen(true);
    }
  };

  const handleAddPlace = async () => {
    if (!selectedPlace) {
      setModalMessage("Seleccione una sede para asignar.");
      setIsWarningOpen(true);
      return;
    }
    setIsLoading(true);

    try {
      await assignPlaceToUser({
        user_id: selectedUser.value,
        place_id: selectedPlace.value,
      });
      setModalMessage("Sede asignada correctamente.");
      setIsSuccessOpen(true);
      loadAssignedPlaces();
      setSelectedPlace("");
    } catch (error) {
      setModalMessage("Error al asignar la sede.");
      setIsWarningOpen(true);
    } finally {
      setIsLoading(false);
    }
  };

  const handleRemovePlace = async (placeId) => {
    setIsLoading(true);
    try {
      await removePlaceFromUser(selectedUser.value, placeId);
      setModalMessage("Sede eliminada correctamente.");
      setIsSuccessOpen(true);
      loadAssignedPlaces();
    } catch (error) {
      setModalMessage("Error al eliminar la sede.");
      setIsWarningOpen(true);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      fullWidth
      maxWidth="md" // Ampliar la modal a "md" para una altura más larga
      sx={{
        "& .MuiDialog-paper": {
          minHeight: "70vh", // Altura mínima de la modal
        },
      }}
    >
      <DialogTitle>Gestionar Sedes de Usuario</DialogTitle>
      <DialogContent dividers>
        {isLoading ? (
          <Box display="flex" justifyContent="center">
            <CircularProgress />
          </Box>
        ) : (
          <Box>
            <Typography variant="h6">Seleccione un Usuario</Typography>
            <Select
              options={users}
              value={selectedUser}
              onChange={setSelectedUser}
              placeholder="Seleccione un usuario"
              isClearable
            />

            {selectedUser && (
              <>
                <Typography variant="h6" sx={{ mt: 2 }}>
                  Sedes Asignadas
                </Typography>
                {assignedPlaces.length !== 0 ? (
                  <List>
                    {assignedPlaces.map(({ id, name_place, address }) => (
                      <ListItem key={id}>
                        <ListItemText
                          primary={name_place}
                          secondary={address}
                        />
                        <ListItemSecondaryAction>
                          <IconButton
                            edge="end"
                            aria-label="delete"
                            onClick={() => handleRemovePlace(id)}
                          >
                            <DeleteIcon color="error" />
                          </IconButton>
                        </ListItemSecondaryAction>
                      </ListItem>
                    ))}
                  </List>
                ) : (
                  <Typography variant="body1">
                    No hay sedes asignadas.
                  </Typography>
                )}

                <Typography variant="h6" sx={{ mt: 2 }}>
                  Agregar Nueva Sede
                </Typography>
                <Grid container spacing={2} alignItems="center">
                  <Grid item xs={8}>
                    <Select
                      options={availablePlaces}
                      value={selectedPlace}
                      onChange={setSelectedPlace}
                      placeholder="Seleccione una sede"
                      isClearable
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handleAddPlace}
                      style={{ backgroundColor: "#0A2139", color: "#FFF" }}
                    >
                      Agregar
                    </Button>
                  </Grid>
                </Grid>
              </>
            )}
          </Box>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} style={{ color: "#1d1d1b" }}>
          Cerrar
        </Button>
      </DialogActions>
      <SuccessMessageModal
        isOpen={isSuccessOpen}
        message={modalMessage}
        onClose={() => setIsSuccessOpen(false)}
      />
      <WarningMessageModal
        isOpen={isWarningOpen}
        message={modalMessage}
        onClose={() => setIsWarningOpen(false)}
      />
    </Dialog>
  );
};

export default ManageUserModal;
