import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Typography,
  Grid,
} from "@mui/material";
import SuccessMessageModal from "@components/Reusable/SuccessMessageModal";
import WarningMessageModal from "@components/Reusable/WarningMessageModal";
import { createTask } from "@services/taskService";

const CreateTaskModal = ({ isOpen, onClose }) => {
  const ORGANIZATION = JSON.parse(sessionStorage.getItem("organization"));
  const [taskData, setTaskData] = useState({
    nombre: "",
    organizacion_id: ORGANIZATION.organizacion_id,
    descripcion: "",
  });

  const [isLoading, setIsLoading] = useState(false);
  const [isSuccessOpen, setIsSuccessOpen] = useState(false);
  const [isWarningOpen, setIsWarningOpen] = useState(false);
  const [modalMessage, setModalMessage] = useState("");

  useEffect(() => {
    if (isOpen) {
      setTaskData({
        nombre: "",
        organizacion_id: ORGANIZATION.organizacion_id,
        descripcion: "",
      });
    }
  }, [isOpen]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setTaskData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSave = async () => {
    const { nombre, organizacion_id, descripcion } = taskData;

    if (!nombre || !descripcion || !organizacion_id) {
      setModalMessage("Todos los campos son obligatorios.");
      setIsWarningOpen(true);
      return;
    }
    try {
      setIsLoading(true);
      await createTask(taskData);
      setModalMessage("Tarea creada exitosamente.");
      setIsSuccessOpen(true);

      setTaskData({
        nombre: "",
        organizacion_id: 1,
        descripcion: "",
      });
    } catch (error) {
      setModalMessage("Error al crear la tarea, posiblemente el nombre de esta tarea ya existe.");
      setIsWarningOpen(true);
    } finally {
      setIsLoading(false);
    }
  };

  const handleSuccessClose = () => {
    setIsSuccessOpen(false);
    onClose();
  };

  return (
    <Dialog open={isOpen} onClose={onClose} fullWidth maxWidth="sm">
      <DialogTitle>Nueva Tarea</DialogTitle>

      <DialogContent dividers>
        {isLoading ? (
          <Box display="flex" justifyContent="center">
            <CircularProgress />
          </Box>
        ) : (
          <Box component="form" mt={2}>
            <Grid container spacing={2}>
              {/* Nombre de la Tarea */}
              <Grid item xs={12}>
                <TextField
                  label="Nombre de la Tarea *"
                  name="nombre"
                  value={taskData.nombre}
                  onChange={handleInputChange}
                  fullWidth
                  variant="outlined"
                  autoFocus
                />
              </Grid>

              {/* Descripción */}
              <Grid item xs={12}>
                <TextField
                  label="Descripción *"
                  name="descripcion"
                  value={taskData.descripcion}
                  onChange={handleInputChange}
                  fullWidth
                  multiline
                  rows={3}
                  variant="outlined"
                />
              </Grid>
            </Grid>
          </Box>
        )}
      </DialogContent>

      <DialogActions>
        {/* Botón Cancelar */}
        <Button onClick={onClose} style={{ color: "#1d1d1b" }}>
          Cancelar
        </Button>

        {/* Botón Guardar */}
        <Button
          onClick={handleSave}
          style={{ backgroundColor: "#0A2139", color: "#f1f1f1" }}
          disabled={isLoading}
          variant="contained"
        >
          Guardar
        </Button>
      </DialogActions>

      {/* Modal de Éxito */}
      <SuccessMessageModal
        isOpen={isSuccessOpen}
        message={modalMessage}
        onClose={handleSuccessClose}
      />

      {/* Modal de Advertencia/Error */}
      <WarningMessageModal
        isOpen={isWarningOpen}
        message={modalMessage}
        onClose={() => setIsWarningOpen(false)}
      />
    </Dialog>
  );
};

export default CreateTaskModal;
