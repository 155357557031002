import React, { useEffect, useState } from "react";
import Select from "react-select";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
  Typography,
  CircularProgress,
} from "@mui/material";
import { getProducts, createProductRegistry } from "@services/inventoryService";
import { getPlaces } from "@services/placeService";
import SuccessMessageModal from "@components/Reusable/SuccessMessageModal";
import WarningMessageModal from "@components/Reusable/WarningMessageModal";

const eventTypeOptions = [
  { value: "entrada", label: "Entrada" },
  { value: "salida", label: "Salida" },
];

const RegisterProductModal = ({ isOpen, onClose }) => {
  const ORGANIZATION = JSON.parse(sessionStorage.getItem("organization"));
  const [registryData, setRegistryData] = useState({
    product_id: "",
    place_id: sessionStorage.getItem("selectedPlaceId")
      ? parseInt(sessionStorage.getItem("selectedPlaceId"), 10)
      : null,
    event_type: { value: "entrada", label: "Entrada" },
    quantity: "",
    date_time: new Date(),
  });

  const [products, setProducts] = useState([]);
  const [places, setPlaces] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isSuccessOpen, setIsSuccessOpen] = useState(false);
  const [isWarningOpen, setIsWarningOpen] = useState(false);
  const [modalMessage, setModalMessage] = useState("");

  useEffect(() => {
    if (isOpen) {
      loadProducts();
      loadPlaces();
    }
  }, [isOpen]);

  const loadProducts = async () => {
    try {
      const response = await getProducts(1,100);
      const productOptions = response.data.data.map((product) => ({
        value: product.id,
        label: product.product_name,
      }));
      setProducts(productOptions);
    } catch (error) {
      console.error("Error loading products:", error);
      setModalMessage("Error al cargar productos.");
      setIsWarningOpen(true);
    }
  };

  const loadPlaces = async () => {
    try {
      const response = await getPlaces(ORGANIZATION.organizacion_id);
      const placeOptions = response.data.map((place) => ({
        value: place.id,
        label: place.name_place,
      }));
      setPlaces(placeOptions);
    } catch (error) {
      console.error("Error loading places:", error);
      setModalMessage("Error al cargar sedes.");
      setIsWarningOpen(true);
    }
  };

  const handleProductChange = (selectedOption) => {
    setRegistryData((prev) => ({
      ...prev,
      product_id: selectedOption ? selectedOption.value : "",
    }));
  };

  const handlePlaceChange = (selectedOption) => {
    setRegistryData((prev) => ({
      ...prev,
      place_id: selectedOption ? selectedOption.value : null,
    }));
  };

  const handleEventTypeChange = (selectedOption) => {
    setRegistryData((prev) => ({
      ...prev,
      event_type: selectedOption,
    }));
  };

  const handleQuantityChange = (event) => {
    setRegistryData((prev) => ({
      ...prev,
      quantity: event.target.value,
    }));
  };

  const handlePhotoCapture = (photo) => {
    setRegistryData((prev) => ({
      ...prev,
      photo: photo.photo,
    }));
  };

  const handleSave = async () => {
    if (
      !registryData.product_id ||
      !registryData.quantity ||
      !registryData.place_id
    ) {
      setModalMessage(
        "Debes seleccionar un producto, cantidad, tipo de evento y sede."
      );
      setIsWarningOpen(true);
      return;
    }

    setIsLoading(true);
    try {
      await createProductRegistry({
        ...registryData,
        event_type: registryData.event_type.value,
      });
      setIsLoading(false);
      setIsSuccessOpen(true);
      setModalMessage("Evento de producto registrado exitosamente.");
      setRegistryData({
        product_id: "",
        place_id: null,
        event_type: { value: "entrada", label: "Entrada" },
        quantity: "",
        date_time: new Date(),
      });
    } catch (error) {
      console.error("Error saving product registry:", error);
      setModalMessage("Cantidad de stock insuficiente.");
      setIsWarningOpen(true);
      setIsLoading(false);
    }
  };

  return (
    <Dialog open={isOpen} onClose={onClose} fullWidth maxWidth="sm">
      <DialogTitle>Registrar Evento de Producto</DialogTitle>
      <DialogContent dividers>
        <Box component="form">
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="subtitle1">Producto</Typography>
              <Select
                value={products.find(
                  (product) => product.value === registryData.product_id
                )}
                onChange={handleProductChange}
                options={products}
                placeholder="Seleccione un producto"
                isClearable
              />
            </Grid>
            <Grid item xs={12}>
              <Typography variant="subtitle1">Tipo de Evento</Typography>
              <Select
                value={registryData.event_type}
                onChange={handleEventTypeChange}
                options={eventTypeOptions}
                placeholder="Seleccione el tipo de evento"
              />
            </Grid>
            <Grid item xs={12}>
              <Typography variant="subtitle1">Cantidad</Typography>
              <TextField
                type="number"
                value={registryData.quantity}
                onChange={handleQuantityChange}
                fullWidth
                placeholder="Ingrese la cantidad"
              />
            </Grid>
            <Grid item xs={12}>
              <Typography variant="subtitle1">Sede</Typography>
              <Select
                value={places.find(
                  (place) => place.value === registryData.place_id
                )}
                onChange={handlePlaceChange}
                options={places}
                placeholder="Seleccione una sede"
                isClearable
              />
            </Grid>
          </Grid>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} style={{ color: "#1b1b1b" }}>
          Cancelar
        </Button>
        <Button
          onClick={handleSave}
          style={{ backgroundColor: "#0A2139", color: "#f1f1f1" }}
          variant="contained"
          disabled={isLoading}
        >
          Guardar
        </Button>
      </DialogActions>
      <SuccessMessageModal
        isOpen={isSuccessOpen}
        message={modalMessage}
        onClose={() => window.location.reload()}
      />
      <WarningMessageModal
        isOpen={isWarningOpen}
        message={modalMessage}
        onClose={() => setIsWarningOpen(false)}
      />
    </Dialog>
  );
};

export default RegisterProductModal;
